import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Grid, Box } from '@material-ui/core';

import {
  Content,
  ContentBlock,
  ContentElement,
  Headline,
  List,
  Text,
} from '../components';

import { border } from '../utils/sharedStyles';
import { getImageFromArray } from '../utils/helpers';

import { introText, hardware, software } from '../data';

const PortaitImage = styled(Img)`
  display: inline-block;
  width: 100%;
`;

const TextWrapper = styled.div`
  min-width: 200px;
  display: inline;
`;

const MoodImg = styled(BackgroundImage)`
  width: 100%;
  height: 370px;
`;

const textStyles = css`
  font: normal normal normal 27px/46px Ibarra Real Nova;
`;

const StyledImageContainer = styled(Grid)`
  margin: -4px;
`;

const StyledGridItem = styled(Grid)`
  padding-bottom: 44px !important;
`;

const Home = () => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          nodes {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    `}
    render={data => {
      const portraitImg = getImageFromArray({
        name: 'studio_01.jpg',
        images: data.allImageSharp.nodes,
      });

      const moodImg = getImageFromArray({
        name: 'studio_02.jpg',
        images: data.allImageSharp.nodes,
      });

      return (
        <Content>
          <ContentBlock borderBottom={border} pb={8.5}>
            <Grid container justify="center" spacing={3}>
              <Box clone order={{ xs: 2, md: 1 }}>
                <StyledImageContainer item xs={12} sm={8} md={6} p={3} pb={1}>
                  <PortaitImage fluid={portraitImg} />
                </StyledImageContainer>
              </Box>
              <Box clone order={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextWrapper>
                    {introText.map(({ paragraph, id }) => (
                      <Text
                        dangerouslySetInnerHTML={{ __html: paragraph }}
                        key={id}
                        styles={textStyles}
                      />
                    ))}
                  </TextWrapper>
                </Grid>
              </Box>
            </Grid>
          </ContentBlock>
          <ContentBlock pt={8.5}>
            <Grid container spacing={3}>
              <StyledGridItem item xs={12}>
                <MoodImg Tag="div" fluid={moodImg} />
              </StyledGridItem>
              <StyledGridItem item xs={12} md={6}>
                <ContentElement
                  borderRight={{ xs: 'none', md: border }}
                  borderBottom={{ xs: border, md: 'none' }}
                  px={{ xs: 0, md: 5 }}
                  pb={7.5}
                  pt={1.5}
                >
                  <Headline semiBold>Hardware</Headline>
                  <List items={hardware} />
                </ContentElement>
              </StyledGridItem>
              <StyledGridItem item xs={12} md={6}>
                <ContentElement px={{ xs: 0, md: 5 }} pb={7.5} pt={1.5}>
                  <Headline semiBold>Software</Headline>
                  <List items={software} />
                </ContentElement>
              </StyledGridItem>
            </Grid>
          </ContentBlock>
        </Content>
      );
    }}
  />
);

export default Home;
